import React from "react";
import PageComponent from "./PageComponent.jsx";

import {Link} from "react-router-dom";
import {
    LineShareButton, LineIcon,
    FacebookShareButton, FacebookIcon,
    TwitterShareButton, TwitterIcon,
    EmailShareButton, EmailIcon
} from "react-share";

import NewsBlock from "./NewsBlock.jsx";

export default class CandidateDetail extends PageComponent {

    constructor(props) {
        super(props);

        this.pageCode = 'candidate-detail';

        let {province, municipality, no} = props.match.params;

        this.province = province;
        this.municipality = municipality;
        this.no =no;

        this.hub = props.hub;

        this.hub.candidates.every(i => {
            if (i.province === province && i.municipality === municipality && parseInt(i.no) == parseInt(no)) {
                this.candidate = i;
                return false;
            } else {
                return true
            }
        });

        this.state = {};

        this.onSaveClick = this.onSaveClick.bind(this);
        this.onSendClick = this.onSendClick.bind(this);
        this.onShareClick = this.onShareClick.bind(this);
        this.onPrintClick = this.onPrintClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.hub.pushPath(this.pageCode);
        this.hub.logStat(this.pageCode, 'candidate-detail-page', {
            candidate: this.candidate
        });
    }

    onSaveClick(e) {
        this.hub.logStat(this.pageCode, 'candidate-save', {
            candidate: this.candidate
        });
    }

    onSendClick(e) {
        this.hub.logStat(this.pageCode, 'candidate-send', {
            candidate: this.candidate
        });
    }

    onShareClick(provider) {
        this.hub.logStat(this.pageCode, 'candidate-share', {
            provider: provider,
            candidate: this.candidate
        });
    }

    onPrintClick(e) {
        e.preventDefault();

        let candidate = this.candidate;

        this.hub.logStat(this.pageCode, 'candidate-print', {
            candidate: this.candidate
        }, () => {
            window.location.href = `/candidates/${candidate.province}/${candidate.municipality}/${candidate.no}/print`;
        });
    }

    render() {
        const item = this.candidate;

        let saveLink;
        if (this.hub.iOS) {
            saveLink = (
                <Link onClick={this.onSaveClick} to={`/candidates/${this.province}/${this.municipality}/${this.no}/image`} className="save-image-link">
                    <span className="fa fa-picture-o fa-1" aria-hidden="true"></span>
                    <span className="d-inline-block">&nbsp; บันทึก</span>
                </Link>
            )

        } else {
            saveLink = (
                <a onClick={this.onSaveClick} href={`https://firebasestorage.googleapis.com/v0/b/thai-vote-municipality-62.appspot.com/o/images%2Fcard%2F${this.province}.${this.municipality}.${this.no}.card.png?alt=media`} download={`${this.province}.${this.no}.card.png`} className="save-image-link">
                    <span className="fa fa-picture-o fa-1" aria-hidden="true"></span>
                    <span className="d-inline-block">&nbsp; บันทึก</span>
                </a>
            )
        }

        const shareUrlEncode = window.location.href;
        const shareUrl = decodeURIComponent(shareUrlEncode);

        let isTerminated = false;
        if (this.hub.terminates.indexOf(item.party) !== -1) {
            isTerminated = true;
        }

        let dagerMessage;

        if (isTerminated) {
            dagerMessage = (
                <div className="alert alert-danger">
                    ! ถูกเพิกถอนสิทธิ์ คุณไม่สามารถเลือกกาเบอร์ {item.no} ได้
                </div>
            );
        }

        return (
            <div className="container">
                <Link to={`/candidates/${item.province}/${item.municipality}`} className="btn btn-back btn-light lh0 d-print-none"><i className="fa fa-chevron-left fa-2"></i></Link>
                <Link to={`/candidates`} className="btn btn-back btn-light lh0 d-print-none float-right">ค้นหาผู้สมัคร <i className="fa fa-search"></i></Link>
                <div className="row justify-content-center">
                    <div className="text-center col-12 col-md-8 col-lg-8 mb-4">
                        <div className="candidate-top">
                            <h3>
                                <Link to={`/candidates/${item.province}/${item.municipality}`}>
                                    <small>{item.province}</small>
                                    <br />
                                    {item.municipality}
                                </Link>
                            </h3>
                            {dagerMessage}
                        </div>
                    </div>
                </div>

                <div className={`candidate-card-wrapper${isTerminated? ' terminated': ''}`}>

                    <div className="row justify-content-center">
                        <div className="text-center col-12 col-md-8 col-lg-8 mb-4">
                            <div className="candidate-top">
                                <h1 className="mb-0">เบอร์ {item.no}</h1>
                            </div>
                        </div>
                    </div>

                    <div className="candidate-card text-center">

                        <div className="candidate-middle bg-dark">
                            <div className="candidate-party-img-wrapper">
                                <a target="_blank" href={`https://www.google.com/search?q="${item.name}"`}>
                                    <div className="candidate-party-img text-white" style={{backgroundImage: `url(/static/images/candidate/${this.province}/${item.municipality}/${item.no}.jpg)`}}>
                                    </div>
                                </a>
                            </div>
                            <h5 className="candidate-name">
                                <a target="_blank" href={`https://www.google.com/search?q="${item.name}"`} className="text-white">
                                    {item.name}
                                </a>
                            </h5>
                        </div>
                        <div className="candidate-bottom">
                            <div className="row mt-3 justify-content-center">

                                <div className="col-4 col-sm-3">
                                    <a onClick={this.onSendClick} href="#" data-toggle="modal" data-target="#send-modal">
                                        <i className="fa fa-send-o fa-1" aria-hidden="true"></i>
                                        <span className="d-inline-block">&nbsp; ส่งต่อ</span>
                                    </a>
                                    <div className="modal fade vertically-modal" id="send-modal" tabIndex="-1" role="dialog">
                                        <div className="modal-dialog modal-sm modal-send" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h4 className="modal-title">
                                                        ส่งต่อให้เพื่อน &nbsp;&nbsp;
                                                        <small><small><i className="fa fa-send-o fa-1" aria-hidden="true"></i></small></small>
                                                    </h4>
                                                    <button type="button" className="close" data-dismiss="modal"
                                                            aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body text-left">
                                                    <div className="share-buttons">
                                                        <span onClick={() => this.onShareClick('line')}>
                                                            <LineShareButton url={shareUrl}><LineIcon/></LineShareButton>
                                                        </span>
                                                        <span onClick={() => this.onShareClick('facebook')}>
                                                            <FacebookShareButton url={shareUrl}><FacebookIcon/></FacebookShareButton>
                                                        </span>
                                                        <span onClick={() => this.onShareClick('twitter')}>
                                                            <TwitterShareButton url={shareUrlEncode}><TwitterIcon/></TwitterShareButton>
                                                        </span>
                                                        <span onClick={() => this.onShareClick('email')}>
                                                            <EmailShareButton url={shareUrl}><EmailIcon/></EmailShareButton>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-4 col-sm-3">
                                    <a href={`/candidates/${item.province}/${item.municipality}/${item.no}/print`} onClick={this.onPrintClick}>
                                        <i className="fa fa-print fa-1" aria-hidden="true"></i>
                                        <span className="d-inline-block">&nbsp; พิมพ์</span>
                                    </a>
                                </div>

                                <div className="col-4 col-sm-3">
                                    {saveLink}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className="d-print-none">
                    <NewsBlock />
                </div> */}
            </div>
        )
    }
}
