import React from "react";

import {Link} from "react-router-dom";
import PageComponent from "./PageComponent.jsx";
import VisualSidebar from "./VisualSidebar.jsx";
import VisualPartyProgress from "./VisualPartyProgress.jsx";

// import Party from "../data/party.json";

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default class VisualSearchProgress extends PageComponent {

    constructor(props) {
        super(props);

        this.hub = props.hub;

        this.provinces = this.hub.zones.map(z => ({id: z.id, name: z.name, children: z.children})).sort((a, b) => {
            const x = a.name.replace(/^([เแโไใ])(.)/, '$2$1')
            const y = b.name.replace(/^([เแโไใ])(.)/, '$2$1')

            if (x > y) {
                return 1
            }
            if (y > x) {
                return -1
            }

            return 0
        })
        this.provincesMap = {}
        this.provinces.forEach(p => {
            this.provincesMap[p.name] = p.id
        })



        this.state = {
            // unitResult: null,

            // dataSource: this.getCrowdDataSource(),

            province: '',
            provinces: [],
            municipality: '',
            municipalities: [],
            zone: '',
            zones: [],
            unit: '',
        }



    }

    async componentDidMount() {

        let callable = await this.hub.functions.httpsCallable('cleanedVoteResultsCallable')
        try {
            let result = await callable({env: this.hub.appMode, source: 'people'})

            let unitResult = result.data
            this.setState({unitResult: unitResult})

            // console.log('unitResult', unitResult)
            
        } catch (e) {
            console.log('err', e)
            // this.setState({loaded: true, åerror: e})
        }

        let province = ''
        let municipality = ''
        let zone = ''
        let unit = ''
        let selectedLocation = getUrlParameter('location')
        
        if (selectedLocation) {
            selectedLocation = selectedLocation.split('--')
            province = selectedLocation[0]
            municipality = selectedLocation[1]
            zone = selectedLocation[2]
            unit = selectedLocation[3]

        }

        console.log('xxxxx', province, municipality, zone, unit)


        this.onProvinceChange({target: {value: province}});
        this.onMunicipalityChange({target: {value: municipality}});
        this.onZoneChange({target: {value: zone}});
        this.onUnitChange({target: {value: unit}});

    }

    async getCrowdDataSource() {
        try {
            let ref = await this.hub.db.doc(`${this.hub.appMode}--cache/elect-results--people`).get()
            if (ref.exists) {
                return JSON.parse(ref.data().data)
            } else {
                return {}
            }
        } catch (e) {
            console.error(e)
        }
    }
    

    
    onProvinceChange = (ev) => {

        let municipalities = this.provinces.filter(province => province.name === ev.target.value)[0].children;

        this.state.province = ev.target.value
        this.setState({
            province: ev.target.value,
            municipality: '',
            municipalities: municipalities,
            zone: '',
            zones: [],
            unit: '',
        })
        this.updateResults()
        
    }

    onMunicipalityChange = (ev) => {

        let zones = [];
        if (ev.target.value) {
            zones = this.state.municipalities.filter(municipality => municipality.name === ev.target.value)[0].children;
        }

        this.state.municipality = ev.target.value
        this.setState({
            municipality: ev.target.value,
            zone: '',
            zones: zones,
            unit: '',
        })
        this.updateResults()
    }

    onZoneChange = (ev) => {

        this.state.zone = ev.target.value,
        this.setState({
            zone: ev.target.value,
            unit: '',
        })
        this.updateResults()
    }

    onUnitChange = async (ev) => {
        let unit = ev.target.value
        this.state.unit = unit
        this.setState({
            unit: unit
        })
        this.updateResults()

    }

    updateResults = async () => {

        let query = this.hub.db.collection(`${this.hub.appMode}--vote-results`)
            .where('parentId', '==', '')
            .where('hasFiles', '==', true)
            .where('markAsAbuse', '==', false)

        if (this.state.province) {
            query = query.where('crowdProvince', '==', this.state.province)
            if (this.state.municipality) {
                query = query.where('crowdMunicipality', '==', this.state.municipality)
                if (this.state.zone) {
                    query = query.where('crowdZone', '==', this.state.zone)
                    if (this.state.unit) {
                        query = query.where('crowdUnit', '==', this.state.unit)
                    }
                }
            }
        }

        let results = await query.orderBy('created', 'asc').get().then(qs => {
            let r = [];
            qs.forEach(item => {
                r.push({id: item.id, ...item.data()});
            });
            return r;
        });


        let groupResults = []
        let  groupResultsMap = {}

        results.forEach(result => {
            let levels = []
            if (result.crowdMunicipality) levels.push(`ท.${result.crowdMunicipality}`);
            if (result.crowdZone) levels.push(`ข.${result.crowdZone}`);
            if (result.crowdUnit) levels.push(`${result.crowdUnit}`);
    
            const label = levels.join(' » ');
            
            if (!groupResultsMap[label]) {
                let groupResult = {label: label, children: [result]}
                groupResultsMap[label] = groupResult
                groupResults.push(groupResult)
            } else {
                groupResultsMap[label].children.push(result)
            }
        })

        this.setState({
            results: groupResults
        })
    }


    renderAlbum = (groupResult, i) => {

        return (
            <div key={i} className={`photo-zone-unit`}>
                {/* <div className={`photo-unit _fw-bd _fs-16 _fs-22-sm`}>{groupResult.label}</div> */}
                <div className={`photo-set d-flex flex-row flex-wrap justify-content-start`}>
                    {groupResult.children.map((result, k) => (
                        result.files.map((file, j) =>
                            <Link key={`${i}-${k}-${j}`}
                                  to={`/crowdsource/digitize/${result.id}?from=${window.location.pathname}${window.location.search}`}
                                  className={`photo-item`}
                                  style={{backgroundImage: 'url("' + file.url + '")'}}>
                            </Link>
                        )
                    ))}
                    
                </div>
                <br />
            </div>
        )
    }

    render() {
        

        const { province, municipality, zone, unit } = this.state

        let progress = this.state.unitResult

        if (!progress) {

            const loader = (
                <div className="report-loading-layer-wrapper text-center" style={{zIndex: '9999'}}>
                    <div className="report-loading-layer" style={{width: '600px', height: '400px', backgroundColor: 'rgba(0,0,0,0.4)'}}>
                        <div className="mx-auto rounded p-4" style={{maxWidth: '200px', width: '100%', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                            <img src="/static/images/preloading.gif" alt="loading ..." style={{width: '100%', height: 'auto', maxWidth: '200px'}}/>
                        </div>
                    </div>
                </div>
            )
            return loader
        }

        let scoreMap = {}

        if (province) {
            progress = progress[province] || {}
        }
        if (municipality) {
            progress = progress[municipality] || {}
        }
        if (zone) {
            progress = progress[zone] || {}
        }
        if (unit) {
            progress = progress[unit] || {}
        }

        console.log('this.state', this.state)

        console.log('progress', progress)

        if (unit) {
            Object.keys(progress).forEach(n => {
                scoreMap[n] = scoreMap[n] || 0
                scoreMap[n] += progress[n]
            })
        } else if (zone) {
            Object.keys(progress).forEach(u => {
                Object.keys(progress[u]).forEach(n => {
                    scoreMap[n] = scoreMap[n] || 0
                    scoreMap[n] += progress[u][n]
                })
            })
        } else if (municipality) {
            Object.keys(progress).forEach(z => {
                Object.keys(progress[z]).forEach(u => {
                    Object.keys(progress[z][u]).forEach(n => {
                        scoreMap[n] = scoreMap[n] || 0
                        scoreMap[n] += progress[z][u][n]
                    })
                })
            })
        } else if (province) {
            Object.keys(progress).forEach(m => {
                Object.keys(progress[m]).forEach(z => {
                    Object.keys(progress[m][z]).forEach(u => {
                        Object.keys(progress[m][z][u]).forEach(n => {
                            scoreMap[n] = scoreMap[n] || 0
                            scoreMap[n] += progress[m][z][u][n]
                        })
                    })
                })
            })
            
        } else {
            Object.keys(progress).forEach(p => {
                Object.keys(progress[p]).forEach(m => {
                    Object.keys(progress[p][m]).forEach(z => {
                        Object.keys(progress[p][m][z]).forEach(u => {
                            Object.keys(progress[p][m][z][u]).forEach(n => {
                                scoreMap[n] = scoreMap[n] || 0
                                scoreMap[n] += progress[p][m][z][u][n]
                            })
                        })
                    })
                })
            })
        }

        const candidates = this.hub.candidates.filter(c => c.province == province && c.municipality == municipality)

        let candidateMap = {}
        candidates.forEach(c => {
            candidateMap[c.no] = c 
        })

        // progress = {
        //     party: Object.keys(scoreMap).map(n => ({key: candidateMap[n]? candidateMap[n].party.replace('พรรค', ''): n, total: scoreMap[n]}))
        //     // party: [
        //     //     {
        //     //         key: '',
        //     //         total: 0
        //     //     }
        //     // ]
        // }

        console.log('scoreMap', scoreMap)
        progress = {
            party: candidates.map(c => ({key: c.no, total: scoreMap[c.no] || 0}))
        }

        return (
            
            <div >
                <div className="visual-wrapper d-flex flex-md-row flex-column justify-content-between">
                    <div className="left pt-sm-5 pt-3">
                        <div className="px-3">
                            <div className="text-center">
                                <h1 className="_lh-1 _fs-30 _fs-sm-42">ผลการนับคะแนนเลือกตั้ง</h1>
                            </div>

                            <div className="row justify-content-center mb-4">
                                <div className="col-12 col-lg-5">

                                    <div className="row justify-content-md-center">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>จังหวัด</label>
                                                        <select value={province} className="form-control" onChange={this.onProvinceChange}>
                                                            {/* <option value="" className="text-muted">- เลือกจังหวัด -</option> */}
                                                            {this.provinces.map(p => {
                                                                return (
                                                                    <option key={p.id} value={p.name}>{p.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>เทศบาล</label>
                                                        <select value={municipality} className="form-control" onChange={this.onMunicipalityChange}>
                                                            <option value="">- เลือกเทศบาล -</option>
                                                            {this.state.municipalities.map(it => {
                                                                return (
                                                                    <option key={it.name} value={it.name}>{it.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>เขตเลือกตั้งที่</label>
                                                        <select value={zone} className="form-control" onChange={this.onZoneChange}>
                                                            <option value="">- เลือกเขตเลือกตั้ง -</option>
                                                            {this.state.zones.map(it => {
                                                                return (
                                                                    <option key={it.name} value={it.name}>{it.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row justify-content-md-center">
                                        <div className="col-12">

                                            <div className="form-group">
                                                <label>หน่วยเลือกตั้งที่</label>
                                                <input type="number" min="1"
                                                    className="form-control"
                                                    placeholder="ระบุตัวเลข"
                                                    value={unit}
                                                    onWheel={(ev) => ev.target.blur()}
                                                    onChange={this.onUnitChange} 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>


                            <VisualPartyProgress hub={this.hub} progress={progress} candidates={candidates} />

                            {this.state.results && this.state.results.length ?
                            <div className="vote-result-bar mx-auto mt-4">
                                <h3>ข้อมูลดิบ</h3>
                                <div className={`d-flex flex-column flex-wrap justify-content-start mb-3`}>
                                    {this.state.results.map((groupResult, i) => this.renderAlbum(groupResult, i))}
                                </div>
                            </div>
                            :
                            ``
                            }

                        </div>
                    </div>

                    <div className={`right`}>
                        <VisualSidebar />
                    </div>
                    
                </div>
            </div>
        )
    }

}