import React from 'react'
import PageComponent from './PageComponent.jsx'
import Autosuggest from 'react-autosuggest';
import Redirect from "react-router-dom/es/Redirect";
import Link from 'react-router-dom/es/Link';
// import CrowdSourcingOpenMask from './CrowdSourcingOpenMask.jsx';

export default class CrowdSourcingZoneDetail extends PageComponent {

    key = `search`

    constructor(props) {
        super(props)

        this.pageCode = 'crowdsource-zone-detail';
        this.hub = props.hub;

        let {province, municipality, location} = props.match.params;

        if (location) {
            let matches = [];
            this.searchLocation([`id-${location}`], this.hub.zones, matches, 1);
            location = matches[0] || null;
        }

        this.state = {

            redirect: false,

            province: province,
            municipality: municipality,
            results: [],
            loading: true,

            // Location
            location: location,
            locationMatches: []
        };
        
        this.state.queryLocation = this.getLocationSuggestionValue(this.getMergeLocation());

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.hub.pushPath(this.pageCode);
        let value = false;
        if (this.state.province && this.state.municipality) {
            value = {province: this.state.province, municipality: this.state.municipality};
        }
        this.hub.logStat(this.pageCode, 'crowdsource-zone-detail-page', value);
        this.updateLocation(this.state.location);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.redirect) {
            this.state.redirect = false;
        }
    }

    // ===================================================
    // Location
    // ===================================================

    searchLocation(keywords, items, matches, limit, parents, root, depth) {

        depth = depth || 0

        var self = this;

        items.forEach(item => {

            if (matches.length >= limit) {
                return false;
            }

            let label = item.postcode ? item.name + ':' + item.postcode: item.name;
            label = parents ? parents + '|' + label : label;

            let province = root? root: item.name;

            let isMatch = !keywords || keywords.filter(keyword => label.indexOf(keyword) !== -1).length >= keywords.length;

            if (item.children && depth < 1) {
                self.searchLocation(keywords, item.children, matches, limit, label, province, depth+1);
            } else if (isMatch) {
                matches.push({
                    label: label,
                    id: item.id,
                    province: province,
                    municipality: item.name,
                });
            }

        });
    }

    getLocationSuggestions = (value) => {
        const keywords = value.toLowerCase().replace(/ »/g, '').trim().split(' ');
        let matches = [];
        if (value.length >= 1) {
            this.searchLocation(keywords, this.hub.zones, matches, 10);
        }
        return matches;
    }


    onLocationChange = (event, { newValue }) => {
        this.setState({
            location: null,
            queryLocation: newValue
        });
    }

    onLocationSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            location: null,
            locationMatches: this.getLocationSuggestions(value)
        });
    }

    onLocationSuggestionsClearRequested = () => {
        this.setState({
            locationMatches: []
        });
    }

    onLocationSelected = (location) => {

        window.localStorage.setItem('province', location.province)
        window.localStorage.setItem('municipality', location.municipality)

        if (location) {
            this.hub.logStat(this.pageCode, 'crowdsource-zone-detail-on-location-select', location);
            this.setState({
                redirect: `/crowdsource/${this.key}/${location.province}/${location.municipality}`
            });
        }
    }

    onLocationSuggestionSelected = (event, { suggestion }) => {
        this.onLocationSelected(suggestion);
    }

    onLocationClear = (event) => {
        this.refs.locationAutosuggest.props.inputProps.value = '';
        this.refs.locationAutosuggest.input.value = '';
        this.refs.locationAutosuggest.input.focus();
        window.localStorage.setItem('province', '')
        window.localStorage.setItem('municipality', '')
        let value = '';
        this.setState({
            location: null,
            queryLocation: value,
            locationMatches: this.getLocationSuggestions(value)
        });
    }

    getLocationSuggestionValue = (item) => {
        if (!item) return '';
        const location = item.label.split('|').map(level => level.split(':')[0]);
        return location.join(' » ');
    }

    shouldRenderLocationSuggestions = (value) => {
        return true;
    }

    renderLocationItem = (item) => {
        return this.getLocationSuggestionValue(item);
    }


    getMergeLocation = () => {
        if (this.state.location) {
            return this.state.location
        } else if (this.state.province && this.state.municipality && this.state.zone) {
            return {province: this.state.province, municipality: this.state.municipality, zone: this.state.zone, label: `${this.state.municipality} เขต ${this.state.zone}`}
        } else if (this.state.province && this.state.municipality) {
            return {province: this.state.province, municipality: this.state.municipality, label: this.state.municipality}
        } else if (this.state.province) {
            return {province: this.state.province, label: this.state.province}
        } else {
            return null
        }
    }

    updateLocation = async () => {

        this.setState({
            loading: true
        });

        let mLocation = this.getMergeLocation();


        if (!this.hub.setting.isInputOpen) {

            this.setState({
                results: [],
                loading: false,
                location: mLocation,
            });

        } else if (!mLocation || (!mLocation.province && !mLocation.municipality && !mLocation.zone)) { // search page

            let results = await this.hub.db.collection(`${this.hub.appMode}--vote-results`)
                .where('parentId', '==', '')
                .where('hasFiles', '==', true)
                .where('markAsAbuse', '==', false)
                .orderBy('totalDigitizes', 'asc')
                .orderBy('totalRenews', 'asc')
                .orderBy('totalVisited', 'asc')
                .orderBy('created', 'asc')
                .get().then(qs => {
                    let r = [];
                    qs.forEach(item => {
                        r.push({id: item.id, ...item.data()});
                    });
                    return r;
                });
            console.log('p results', results)

            this.setState({
                results: results,
                loading: false,
                location: mLocation,
            });

        } else if (!mLocation.municipality && !mLocation.zone) { // province page


            let results = await this.hub.db.collection(`${this.hub.appMode}--vote-results`)
                .where('parentId', '==', '')
                .where('hasFiles', '==', true)
                .where('markAsAbuse', '==', false)
                .where('crowdProvince', '==', mLocation.province)
                .orderBy('totalDigitizes', 'asc')
                .orderBy('totalRenews', 'asc')
                .orderBy('totalVisited', 'asc')
                .orderBy('created', 'asc')
                .get().then(qs => {
                    let r = [];
                    qs.forEach(item => {
                        r.push({id: item.id, ...item.data()});
                    });
                    return r;
                });

                console.log('m results', results)

            this.setState({
                results: results,
                loading: false,
                location: mLocation,
            });

        } else if (!mLocation.zone) { // province page

            let results = await this.hub.db.collection(`${this.hub.appMode}--vote-results`)
                .where('parentId', '==', '')
                .where('hasFiles', '==', true)
                .where('markAsAbuse', '==', false)
                .where('crowdProvince', '==', mLocation.province)
                .where('crowdMunicipality', '==', mLocation.municipality)
                .orderBy('totalDigitizes', 'asc')
                .orderBy('totalRenews', 'asc')
                .orderBy('totalVisited', 'asc')
                .orderBy('created', 'asc')
                .get().then(qs => {
                    let r = [];
                    qs.forEach(item => {
                        r.push({id: item.id, ...item.data()});
                    });
                    return r;
                });

            this.setState({
                results: results,
                loading: false,
                location: mLocation,
            });

        } else { // zone page

            let results = await this.hub.db.collection(`${this.hub.appMode}--vote-results`)
                .where('parentId', '==', '')
                .where('hasFiles', '==', true)
                .where('markAsAbuse', '==', false)
                .where('crowdProvince', '==', mLocation.province)
                .where('crowdMunicipality', '==', mLocation.municipality)
                .where('crowdZone', '==', `${mLocation.zone}`)
                .orderBy('totalDigitizes', 'asc')
                .orderBy('totalRenews', 'asc')
                .orderBy('totalVisited', 'asc')
                .orderBy('created', 'asc')
                .get().then(qs => {
                    let r = [];
                    qs.forEach(item => {
                        r.push({id: item.id, ...item.data()});
                    });
                    return r;
                });

            this.setState({
                results: results,
                loading: false

            });
        }

    }

    getThumb = (url) => {
        const dirName = 'uploads%2Fvote-results%2F';

        if (url.indexOf('futurista') !== -1) {
            url = `https://images.weserv.nl/?url=${url}&w=256&h=256&t=square&a=center`;
        } else {
            url = url.replace(dirName, `${dirName}thumb@256_`);
        }
        return url;
    }

    renderAlbum = (result, i) => {
        let levels = []
        if (result.crowdMunicipality) levels.push(`ท.${result.crowdMunicipality}`);
        if (result.crowdZone) levels.push(`ข.${result.crowdZone}`);
        if (result.crowdUnit) levels.push(`น.${result.crowdUnit}`);

        const label = levels.join(' » ');


        // return (
        //     <div key={i} className={`photo-zone-unit`}>
        //         <div className={`photo-unit _fw-bd _fs-16 _fs-22-sm d-none`}>{label}</div>
        //         <div className={`photo-set d-flex flex-row flex-wrap justify-content-start`}>
        //             {result.files.map((file, j) =>
        //                 <Link key={`${i}-${j}`}
        //                       to={`/crowdsource/digitize/${result.id}?from=${window.location.pathname}`}
        //                       className={`photo-item`}
        //                       style={{backgroundImage: 'url("' + file.url + '")'}}>
        //                 </Link>
        //             )}
        //         </div>
        //     </div>
        // )

        return result.files.map((file, j) =>
            <Link key={`${i}-${j}`}
                    to={`/crowdsource/digitize/${result.id}?from=${window.location.pathname}`}
                    className={`photo-item`}
                    style={{backgroundImage: 'url("' + this.getThumb(file.url) + '")'}}>

                {result.totalDigitizes == 0?
                <div className={`total-digitizes d-inline-block`}>
                    <div className={`total-digitizes-inner`}>
                    </div>
                </div>
                :
                <>
                {[...Array(Math.min(result.totalDigitizes, 5)).keys()].map(i =>
                <div key={i} className={`total-digitizes d-inline-block exist`}>
                    <div className={`total-digitizes-inner`}>
                    </div>
                </div>
                )}
                </>
                }
            </Link>
        )
    }

    renderPageContent = () => {


        const loader = (
            <div className="report-loading-layer-wrapper text-center" style={{zIndex: '9999'}}>
                <div className="report-loading-layer" style={{width: '600px', height: '400px', backgroundColor: 'rgba(0,0,0,0.4)'}}>
                    <div className="mx-auto rounded p-4" style={{maxWidth: '200px', width: '100%', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                        <img src="/static/images/preloading.gif" alt="loading ..." style={{width: '100%', height: 'auto', maxWidth: '200px'}}/>
                    </div>
                </div>
            </div>
        )

        let mLocation = this.getMergeLocation();


        if (!mLocation || (!mLocation.province && !mLocation.municipality && !mLocation.zone)) { // search page
            
            return (
                <div className={`container-fluid py-3 flex-grow-1 bg-crowdsourcing`}>
                    <div className={``}>
                        <div className={`p-3 bg-white shadow-1dp`}>
                            <div className={`d-flex flex-column`}>
                                <h4 className={`_fw-med _fs-32 mb-3`}>
                                    รูปกระดานคะแนน ที่ยังไม่ได้กรอกคะแนน หรือ ข้อมูลหน่วยเลือกตั้ง
                                </h4>
                            </div>
                            <div className={`d-flex flex-row flex-wrap justify-content-start mb-3`}>
                                {this.state.loading ? loader : this.state.results.length ?
                                    this.state.results.map((result, i) => this.renderAlbum(result, i))
                                    :
                                    <div className={`mt-5`}><h3>ยังไม่มีข้อมูลดิบที่รอการกรอกคะแนน</h3></div>
                                }
                            </div>
                            
                        </div>
                    </div>
                </div>
            )

        } else if (!mLocation.municipality && !mLocation.zone) { // province page
            return (
                <div className={`container-fluid py-3 flex-grow-1 bg-crowdsourcing`}>
                    <div className={`container`}>
                        <div className={`p-3 bg-white shadow-1dp`}>
                            <div className={`d-flex flex-column`}>
                                <h3 className={`_fw-med _fs-36`}>
                                    {this.state.province}
                                </h3>
                            </div>
                            <div className={`d-flex flex-row flex-wrap justify-content-start mb-3`}>
                                {this.state.loading ? loader : this.state.results.length ?
                                    this.state.results.map((result, i) => this.renderAlbum(result, i))
                                    :
                                    <div className={`mt-5`}>
                                        <div className={`alert alert-warning`}>
                                            <h3 className={`mb-0`}>ยังไม่มีข้อมูลดิบที่จังหวัดนี้</h3>
                                        </div>
                                    </div>
                                }
                            </div>
                            
                        </div>
                    </div>
                </div>
            )
        } else if (!mLocation.zone) { // municipality page
            return (
                <div className={`container-fluid py-3 flex-grow-1 bg-crowdsourcing`}>
                    <div className={`container`}>
                        <div className={`p-3 bg-white shadow-1dp`}>
                            <div className={`d-flex flex-column`}>
                                <h3 className={`_fw-med _fs-36`}>
                                    <small>{this.state.province}</small>
                                    <br />
                                    {this.state.municipality}
                                </h3>
                            </div>
                            <div className={`d-flex flex-row flex-wrap justify-content-start mb-3`}>
                                {this.state.loading ? loader : this.state.results.length ?
                                    this.state.results.map((result, i) => this.renderAlbum(result, i))
                                    :
                                    <div className={`mt-5`}>
                                        <div className={`alert alert-warning`}>
                                            <h3 className={`mb-0`}>ยังไม่มีข้อมูลดิบที่เทศบาลนี้</h3>
                                        </div>
                                    </div>
                                }
                            </div>
                            
                        </div>
                    </div>
                </div>
            )
        } else { // zone page
            return (
                <div className={`container-fluid py-3 flex-grow-1 bg-crowdsourcing`}>
                    <div className={`container`}>
                        <div className={`p-3 bg-white shadow-1dp`}>
                            <div className={`d-flex flex-column`}>
                                <h3 className={`_fw-med _fs-36 _lh-80 mb-0`}>
                                    <small>{this.state.province}</small>
                                    <br />
                                    {this.state.municipality} เขต {this.state.zone}
                                </h3>
                            </div>
                            <div className={`d-flex flex-row flex-wrap justify-content-start mb-3`}>
                                {this.state.loading ? loader : this.state.results.length ?
                                    this.state.results.map((result, i) => this.renderAlbum(result, i))
                                    :
                                    <div className={`mt-5`}><h3>ยังไม่มีข้อมูลดิบที่เขตนี้</h3></div>
                                }
                            </div>
                        </div>

                        
                    </div>
                </div>
            )
        }

    }

    render = () => {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const {
            queryLocation, locationMatches
        } = this.state;

        const locationInputProps = {
            id: 'input-location',
            placeholder: 'ค้นหาโดยพิมพ์ชื่อจังหวัด เทศบาล',
            className: 'form-control _fw-med',
            value: queryLocation,
            onChange: this.onLocationChange
        };


        let locationButton;
        if (queryLocation) {
            locationButton = (
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button">
                        <Link to={`/crowdsource/${this.key}`} onClick={e => {window.localStorage.setItem('province', ''); window.localStorage.setItem('municipality', '');}}><i className={`fa fa-close d-flex align-items-center`}> </i></Link>
                    </button>
                </div>
            );
        } else {
            locationButton = (
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button"><img src="/static/images/ic-search.png" srcSet="/static/images/ic-search.png 1x, /static/images/ic-search@2x.png 2x" width="18px"/></button>
                </div>
            );
        }

        const resultActiveButton = {
            border: this.key == 'result' ? `2px solid #50D1A6` : `2px solid transparent`,
        }
        const searchActiveButton = {
            border: this.key == 'search' ? `2px solid #50D1A6` : `2px solid transparent`,
        }

        return (
            <div className={`bg-light-grey content d-flex flex-column flex-grow-1`}>
                <div className={`container-fluid py-4 bg-light-grey`}>
                    <h2 className={`_fw-med _fs-26 mb-3 text-center text-white`}>ดูข้อมูลดิบของแต่ละหน่วย</h2>

                    <div className="text-center mt-4 mb-4">
                        <div className="btn-group text-center" role="group">
                            <Link to={`/crowdsource/result/${queryLocation}`} 
                                className={`btn btn-secondary d-flex align-items-center _fw-med _fs-18 ${this.key == 'result' ? 'active': ''}`}
                                style={resultActiveButton}
                            >
                                <div className="d-flex align-items-center">
                                    <i className={`fa mr-2 _fs-10 
                                        ${this.key == 'result' ? 'fa-circle text-active-green': 'fa-circle-o text-white'}
                                    `}></i>
                                    หน่วยที่มีผลคะแนน
                                </div>
                            </Link>
                            <Link to={`/crowdsource/search/${queryLocation}`} 
                                className={`btn btn-secondary _fw-med _fs-18 ${this.key == 'search' ? 'active': ''}`}
                                style={searchActiveButton}
                            >
                                <div className="d-flex align-items-center">
                                    รูปกระดานคะแนน 
                                    <i className={`fa ml-2 _fs-16 
                                        ${this.key == 'search' ? 'fa-picture-o text-active-green': 'fa-picture-o text-white'}
                                    `}></i>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className={`search-switch-wrapper mx-auto d-flex flex-row justify-content-between align-items-center`}>
                        <div className={`search-box-wrapper -zone-detail text-center d-flex flex-row align-items-center`}>
                            <div className={`input-group`}>
                                <Autosuggest
                                    ref="locationAutosuggest"
                                    suggestions={locationMatches}
                                    onSuggestionsFetchRequested={this.onLocationSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onLocationSuggestionsClearRequested}
                                    onSuggestionSelected={this.onLocationSuggestionSelected}
                                    getSuggestionValue={this.getLocationSuggestionValue}
                                    shouldRenderSuggestions={this.shouldRenderLocationSuggestions}
                                    renderSuggestion={this.renderLocationItem}
                                    inputProps={locationInputProps}
                                />
                                {locationButton}
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderPageContent()}
            </div>
        )
    }
}