import React from "react";
import PageComponent from "./PageComponent.jsx";

import Autosuggest from 'react-autosuggest';
import {Link} from "react-router-dom";
import HomeFooter from "./HomeFooter.jsx";

export default class SearchForm extends PageComponent {
    constructor(props) {
        super(props);

        this.pageCode = 'search-form';

        this.allCandidates = {
            no: '',
            party: '- เลือกทุกผู้สมัคร/พรรคการเมือง',
        };
        this.allSncCandidates = {
            no: '',
            party: '- เลือกเฉพาะพรรคที่สนับสนุน คสช.',
        };
        this.allNncCandidates = {
            no: '',
            party: '- เลือกเฉพาะพรรคที่ไม่สนับสนุน คสช.',
        };
        this.allXncCandidates = {
            no: '',
            party: '- เลือกเฉพาะพรรคที่จุดยืนไม่ชัดเจน',
        };

        this.hub = props.hub;

        let location = JSON.parse(window.localStorage.getItem('location'));
        // let candidate = JSON.parse(window.localStorage.getItem('candidate'));

        this.state = {
            location: location,
            queryLocation: this.getLocationSuggestionValue(location),
            locationMatches: [],

            candidate: null,
            queryCandidate: this.getCandidateSuggestionValue(this.allCandidates),
            candidateMatches: [],

            ageRange: this.hub.ageRange,
            filter: null
        };


        // Location
        this.searchLocation = this.searchLocation.bind(this);
        this.getLocationSuggestions = this.getLocationSuggestions.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.onLocationSuggestionsFetchRequested = this.onLocationSuggestionsFetchRequested.bind(this);
        this.onLocationSuggestionsClearRequested = this.onLocationSuggestionsClearRequested.bind(this);
        this.onLocationSuggestionSelected = this.onLocationSuggestionSelected.bind(this);
        this.onLocationClear = this.onLocationClear.bind(this);
        this.getLocationSuggestionValue = this.getLocationSuggestionValue.bind(this);
        this.shouldRenderLocationSuggestions = this.shouldRenderLocationSuggestions.bind(this);
        this.renderLocationItem = this.renderLocationItem.bind(this);

        // Candidate
        this.searchCandidate = this.searchCandidate.bind(this);
        this.getCandidateSuggestions = this.getCandidateSuggestions.bind(this);
        this.onCandidateChange = this.onCandidateChange.bind(this);
        this.onCandidateSuggestionsFetchRequested = this.onCandidateSuggestionsFetchRequested.bind(this);
        this.onCandidateSuggestionsClearRequested = this.onCandidateSuggestionsClearRequested.bind(this);
        this.onCandidateSuggestionSelected = this.onCandidateSuggestionSelected.bind(this);
        this.onCandidateClear = this.onCandidateClear.bind(this);
        this.getCandidateSuggestionValue = this.getCandidateSuggestionValue.bind(this);
        this.shouldRenderCandidateSuggestions = this.shouldRenderCandidateSuggestions.bind(this);
        this.renderCandidateItem = this.renderCandidateItem.bind(this);

        // Age
        this.onAgeRangeChange = this.onAgeRangeChange.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.hub.pushPath(this.pageCode);
        this.hub.logStat(this.pageCode, 'search-form-page');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // super.componentDidUpdate(prevProps, prevState, snapshot);
        window.localStorage.setItem('ageRange', this.state.ageRange);
        window.localStorage.setItem('location', JSON.stringify(this.state.location));
        // window.localStorage.setItem('candidate', JSON.stringify(this.state.candidate));
    }

    // ===================================================
    // Location
    // ===================================================

    searchLocation(keywords, items, matches, limit, parents, root, depth) {

        depth = depth || 0

        var self = this;

        items.forEach(item => {

            if (matches.length >= limit) {
                return false;
            }

            let label = item.postcode ? item.name + ':' + item.postcode: item.name;
            label = parents ? parents + '|' + label : label;

            let province = root? root: item.name;

            let isMatch = !keywords || keywords.filter(keyword => label.indexOf(keyword) !== -1).length >= keywords.length;

            if (item.children && depth < 1) {
                self.searchLocation(keywords, item.children, matches, limit, label, province, depth+1);
            } else if (isMatch) {
                matches.push({
                    label: label,
                    id: item.id,
                    province: province,
                    municipality: item.name,
                });
            }

        });
    }

    getLocationSuggestions(value) {
        const keywords = value.toLowerCase().replace(/ »/g, '').trim().split(' ');
        let matches = [];
        if (value.length >= 1) {
            this.searchLocation(keywords, this.hub.zones, matches, 10);
        }
        return matches;
    }


    onLocationChange(event, { newValue }) {
        this.setState({
            location: null,
            queryLocation: newValue,

            candidate: null,
            queryCandidate: this.getCandidateSuggestionValue(this.allCandidates),
            candidateMatches: [],
        });
    }

    onLocationSuggestionsFetchRequested({ value }) {
        this.setState({
            location: null,
            locationMatches: this.getLocationSuggestions(value),

            candidate: null,
            queryCandidate: this.getCandidateSuggestionValue(this.allCandidates),
            candidateMatches: [],
        });
    }

    onLocationSuggestionsClearRequested() {
        this.setState({
            locationMatches: []
        });
    }

    onLocationSuggestionSelected(event, { suggestion }) {

        console.log("onLocationSuggestionSelected", suggestion)
        this.setState({location: suggestion});
        this.hub.logStat(this.pageCode, 'select-location', suggestion);
    }

    onLocationClear(event) {
        this.refs.locationAutosuggest.props.inputProps.value = '';
        this.refs.locationAutosuggest.input.value = '';
        this.refs.locationAutosuggest.input.focus();

        let value = '';
        this.setState({
            location: null,
            queryLocation: value,
            locationMatches: this.getLocationSuggestions(value),

            candidate: null,
            queryCandidate: this.getCandidateSuggestionValue(this.allCandidates),
            candidateMatches: [],
        });
    }

    getLocationSuggestionValue(item) {
        if (!item) return '';
        const location = item.label.split('|').map(level => level.split(':')[0]);
        return location.join(' » ');
    }

    shouldRenderLocationSuggestions(value) {
        return true;
    }

    renderLocationItem(item) {
        return this.getLocationSuggestionValue(item);
    }


    // ===================================================
    // Candidate
    // ===================================================

    searchCandidate(keywords, items, matches, limit) {

        items.forEach(item => {

            if (matches.length >= limit) {
                return false;
            }

            let label = this.getCandidateSuggestionValue(item);

            let isMatch = !keywords || keywords.filter(keyword => label.indexOf(keyword) !== -1).length >= keywords.length;

            if (isMatch) {
                matches.push(item);
            }

        });
    }

    getCandidateSuggestions(value) {
        const keywords = value.toLowerCase().replace(/ »/g, '').trim().split(' ');
        let matches = [];

        let candidates = this.hub.candidates;
        let location = this.state.location;
        if (location) {
            candidates = candidates.filter(candidate => candidate.province === location.province && candidate.municipality === location.municipality);
        } else {
            candidates = [];
        }
        this.searchCandidate(keywords, candidates, matches, 100);

        const vowel = [
            "ไ", "โ", "ใ", "เ", "แ",
            // "ิ", "ี", "ึ", "ื", "ุ", "ู", "ั", "ํ", "์", "ฺ", "็", "่", "้", "๊", "๋"
        ];

        let vowelTrim = (str) => {
            let at = 0;
            for (var i = 0; i < str.length; i++) {
                at = i;
                if (vowel.indexOf(str.charAt(i)) === -1) {
                    break;
                }
            }
            return str.slice(at);
        };
        matches.sort((a, b) => {
            let aParty = vowelTrim(a.party.replace('พรรค', ''));
            let bParty = vowelTrim(b.party.replace('พรรค', ''));


            if (aParty < bParty)
                return -1;
            if (aParty > bParty)
                return 1;
            return 0;
        });

        if (!this.hub.xncTransparent) {
            matches.unshift(this.allXncCandidates);
        }
        // matches.unshift(this.allNncCandidates);
        // matches.unshift(this.allSncCandidates);
        // matches.unshift(this.allCandidates);
        return matches;
    }


    onCandidateChange(event, { newValue }) {
        this.setState({
            queryCandidate: newValue
        });
    }

    onCandidateSuggestionsFetchRequested({ value }) {

        this.setState({
            candidateMatches: this.getCandidateSuggestions(value)
        });
    }

    onCandidateSuggestionsClearRequested() {
        this.setState({
            candidateMatches: []
        });
    }

    onCandidateSuggestionSelected(event, { suggestion }) {
        let candidate = null;
        let filter = null;

        if ([this.allCandidates, this.allSncCandidates, this.allNncCandidates, this.allXncCandidates].indexOf(suggestion) === -1) {
            candidate = suggestion;
        } else if (suggestion !== candidate) {
            const index = [this.allSncCandidates, this.allNncCandidates, this.allXncCandidates].indexOf(suggestion);
            filter = [0, 0, 0];
            filter[index] = 1;
            filter = filter.join(',')
        }

        this.setState({
            candidate: candidate,
            queryCandidate: this.getCandidateSuggestionValue(suggestion),
            filter: filter
        });

        if (candidate) {
            this.hub.logStat(this.pageCode, 'select-candidate', candidate);
        }
    }

    onCandidateClear(event) {
        this.refs.candidateAutosuggest.props.inputProps.value = '';
        this.refs.candidateAutosuggest.input.value = '';
        this.refs.candidateAutosuggest.input.focus();

        let value = '';
        this.setState({
            queryCandidate: value,
            candidateMatches: this.getCandidateSuggestions(value)
        });
    }

    getCandidateSuggestionValue(item) {
        if (!item) return '';
        return item.party + (item.prefix? ' » ' + item.prefix + item.firstname + ' ' + item.lastname: '');
    }

    shouldRenderCandidateSuggestions(value) {
        return true;
    }

    renderCandidateItem(item) {
        let suffix;
        if (item.prefix || item.firstname || item.lastname) {
            suffix = (<div><strong>{item.prefix}{item.firstname} {item.lastname}</strong></div>);
        }
        return (
            <div>
                <div><strong>{suffix}</strong></div>
                {/* <small>{item.party ? item.party : `ผู้สมัครอิสระ`}</small> */}
            </div>
        );
    }

    // ===================================================
    // Age
    // ===================================================

    onAgeRangeChange(event) {

        let ageRange = event.target.value;
        this.setState({
            ageRange: ageRange
        });

        this.hub.logStat(this.pageCode, 'select-age-range', {
            ageRange: ageRange
        });
        this.hub.ageRange = ageRange; // update app variable

    }

    render() {
        const {
            queryLocation, locationMatches,
            queryCandidate, candidateMatches
        } = this.state;

        const locationInputProps = {
            id: 'input-location',
            placeholder: 'ค้นหาโดยพิมพ์ชื่อจังหวัด เทศบาล',
            value: queryLocation,
            onChange: this.onLocationChange
        };

        let locationCloseButton;
        if (queryLocation) {
            locationCloseButton = (
                <div className="close-icon-container">
                    <button className="btn btn-outline btn-sm btn-close" onClick={this.onLocationClear}>
                        <i className="fa fa-close"> </i>
                    </button>
                </div>
            );
        }

        const candidateInputProps = {
            id: 'input-candidate',
            placeholder: 'ค้นชื่อผู้สมัคร',
            value: queryCandidate,
            onChange: this.onCandidateChange,
            onClick: this.onCandidateClear,
            disabled: !this.state.location
        };

        let candidateCloseButton;
        if (queryCandidate && this.state.location) {
            candidateCloseButton = (
                <div className="close-icon-container">
                    <button className="btn btn-outline btn-sm btn-close" onClick={this.onCandidateClear}>
                        <i className="fa fa-close"> </i>
                    </button>
                </div>
            );
        }

        let ageRage = this.state.ageRange;
        let candidate = this.state.candidate;
        let location = this.state.location;
        let filter = this.state.filter;

        let checkLinkText = 'ตรวจดูหมายเลขผู้สมัคร';
        let checkLink;
        if (candidate) {
            checkLink = (
                <Link to={`/candidates/${candidate.province}/${candidate.municipality}/${candidate.no}`} className="btn bg-orange btn-lg text-white">{checkLinkText}</Link>
            )
        } else if (location) {
            checkLink = (
                <Link to={`/candidates/${location.province}/${location.municipality}${filter?'?filter=' + filter: ''}`} className="btn bg-orange btn-lg text-white">{checkLinkText}</Link>
            )
        } else {
            checkLink = (
                <a className="btn btn-secondary btn-search disabled btn-lg">{checkLinkText}</a>
            )
        }
        
        const startInputDateTime = this.hub.setting.startInputDateTime

        return (
            <div className="wrapper-search d-flex align-items-center flex-column bg-dark">
                <div className="container">
                    {/* <div className="row row">
                        <div className="col-12 col-md-6 mx-auto">
                            <img className="img-fluid mb-2 mb-md-3" src="https://firebasestorage.googleapis.com/v0/b/thai-vote-municipality-62.appspot.com/o/images%2Fcontent%2FElection%20Manual%20Banner.png?alt=media" />
                        </div>
                    </div> */}
                    
                    <h4 className="mt-3 text-center text-white">
                        ค้นหาผู้สมัครเลือกตั้ง นายกเทศมนตรี
                    </h4>
                    <div className={`text-center`}>
                        <img src="/static/images/municipality.gif" alt=""/>
                    </div>
                    <div className="candidate-form-wrapper">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10 col-md-6">
                                <div className="form-group pb-2">
                                    <div className="react-autosuggest-wrapper input-location-wrapper">
                                        <Autosuggest
                                            ref="locationAutosuggest"
                                            suggestions={locationMatches}
                                            onSuggestionsFetchRequested={this.onLocationSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onLocationSuggestionsClearRequested}
                                            onSuggestionSelected={this.onLocationSuggestionSelected}
                                            getSuggestionValue={this.getLocationSuggestionValue}
                                            shouldRenderSuggestions={this.shouldRenderLocationSuggestions}
                                            renderSuggestion={this.renderLocationItem}
                                            inputProps={locationInputProps}
                                        />
                                        {locationCloseButton}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-12 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="input-candidate">ค้นชื่อผู้สมัคร</label>
                                    <div className="react-autosuggest-wrapper input-candidate-wrapper">
                                        <Autosuggest
                                            ref="candidateAutosuggest"
                                            suggestions={candidateMatches}
                                            onSuggestionsFetchRequested={this.onCandidateSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onCandidateSuggestionsClearRequested}
                                            onSuggestionSelected={this.onCandidateSuggestionSelected}
                                            getSuggestionValue={this.getCandidateSuggestionValue}
                                            shouldRenderSuggestions={this.shouldRenderCandidateSuggestions}
                                            renderSuggestion={this.renderCandidateItem}
                                            inputProps={candidateInputProps}
                                        />
                                        {candidateCloseButton}
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-12">
                                <div className="text-center mt-0"> {checkLink}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <HomeFooter hub={this.hub} />
            </div>
        )
    }
}
