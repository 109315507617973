import React from "react";

import PageComponent from "./PageComponent.jsx";
import * as axios from 'axios'

export default class VisualTable extends PageComponent {

    constructor(props) {
        super(props);

        this.hub = props.hub;
    }

    async componentDidMount() {
        let ref = await this.hub.db.doc(`${this.hub.appMode}--cache/elect-results--people`).get()
        let unitResults = await axios.get(`https://us-central1-thai-vote-municipality-62.cloudfunctions.net/unitResults?env=prod&source=people`)
        unitResults = unitResults.data

        this.setState({
            data: JSON.parse(ref.data().data),
            unitResults: unitResults,
        })

    }

    render() {

        let unitList = []
        this.state && Object.keys(this.state.unitResults).forEach(province => {
            unitList.push({
                province: province,
                total: this.state.unitResults[province].total,
            })
        })

        const popularSort = (a, b) => {
            return (a.total > b.total) ? -1 : ((b.total > a.total) ? 1 : 0)
        }
        unitList.sort(popularSort)

        const totalUnits = unitList.reduce((acc, b) => acc + this.state.unitResults[b.province].total, 0)

        return (
            <div className="container">
                {this.state && this.state.data && 
                <table className={`table table-bordered`}>
                    <thead className={`thead-light`}>
                        <tr>
                            <th colSpan={100}>ตารางคะแนน</th>
                        </tr>
                        <tr>
                            <th>เบอร์</th><th>ชื่อ</th><th>สังกัด</th><th>คะแนน</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.province.map(province => (
                        <>
                            <tr className={`bg-dark text-white`}>
                                <td colSpan={100}>{province.key}</td>
                            </tr>
                            {province.party.map(item => {
                                return (
                                    <tr>
                                        {item.key.split('|').map(it => <td>{it}</td>)}
                                        <td>{item.total}</td>
                                    </tr>
                                )
                            })}
                        </>
                        ))}

                        
                    </tbody>
                </table>
                }

                <br /><br />

                <table className={`table table-bordered`}>
                    <thead className={`thead-light`}>
                        <tr>
                            <th colSpan={100}>ตารางหน่วยเลือกตั้งที่มีคะแนนแล้ว</th>
                        </tr>
                        <tr>
                            <th>จังหวัด</th><th>จำนวนหน่วยที่ได้</th>
                        </tr>
                    </thead>
                    <tbody>
                        {unitList.map(unit => (
                        <tr key={unit.province} className={``}>
                            <td>{unit.province}</td>
                            <td>{unit.total}</td>
                        </tr>
                        ))}  
                        <tr>
                            <td>รวม</td>
                            <td>{totalUnits}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}