import React from "react";
import PageComponent from "./PageComponent.jsx";

import {Link} from "react-router-dom";
import Party from "../data/party.json";
import NewsBlock from "./NewsBlock.jsx";

function getQueryVariable(search) {
    var map = {};
    var query = search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        map[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
    }
    return map;
}

function numberWithCommas(x) {
    return (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default class LocationDetail extends PageComponent {

    constructor(props) {
        super(props);

        this.pageCode = 'location-detail';

        const {province, municipality} = props.match.params;

        this.hub = props.hub;

        this.basecCandidates = this.hub.candidates.filter(i => i.province === province && i.municipality === municipality);

        this.candidates = this.basecCandidates;
        this.province = province;
        this.municipality = municipality;

        const query = getQueryVariable(this.props.location.search);
        const defaultFilter = '0,0,0';

        const filter = (query.filter || defaultFilter).split(',').map(i => Boolean(parseInt(i)));

        this.state = {
            filter: filter
        };
        this.rawFilter = query.filter;

        this.filterCandidates();
        this.filterTimeout = null;
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.hub.pushPath(this.pageCode);
        let data = {
            province: this.province,
            municipality: this.municipality,
        };
        if (this.rawFilter) {
            data.filter = this.state.filter
        }
        this.hub.logStat(this.pageCode, 'location-detail-page', data);
    }

    filterCandidates() {
        const filter = this.state.filter;

        this.candidates = this.basecCandidates.filter(item => {

            item.badge = 'xnc';
            return true;

        });
    }

    toggleFilter(i) {
        let filter = this.state.filter;
        filter[i] = !filter[i];
        this.filterCandidates();

        this.props.history.push({
            search   : '?filter=' + this.state.filter.map(i => 0+i).join(',')
        });

        let self = this;

        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {

            self.hub.logStat(self.pageCode, 'location-detail-page', {
                province: self.province,
                municipality: self.municipality,
                filter: self.state.filter
            });

        }, 5000)
    }

    renderCandidateItem(item) {
        let key = `/candidates/${item.province}/${item.municipality}/${item.no}`;

        const partyName = item.party ? item.party.replace('พรรค', ''): ``;
        let color = Party[partyName] ? Party[partyName].color: '#000000'

        let isTerminated = false;
        if (this.hub.terminates.indexOf(item.party) !== -1) {
            isTerminated = true;
        }

        return (
            <div key={key} className="candidate-item-wrapper col-xs-12 col-md-6 col-xl-4">
                <Link to={key} className={`candidate-item d-flex align-items-center${isTerminated ? ' terminated': ''}`}>
                    
                    <div className="candidate-item-right w-100">
                        <div className={`candidate-item-badge badge-${item.badge}`} style={{borderLeft: `6px solid ${color}`}}> 
                        </div>
                        <div className="candidate-item-left">
                            <h4>เบอร์</h4>
                            <h2>{item.no}</h2>
                        </div>
                        <div className="candidate-item-body">
                            <h3 className="candidate_title">
                                {item.name}
                            </h3>
                            <h5 className="candidate_name">
                                {isTerminated? `- โดนตัดสิทธิ์ -`: ``}
                            </h5>
                        </div>
                        <div className="candidate-party-img-wrapper">
                            <div className="candidate-party-img text-white" style={{backgroundSize: 'cover', backgroundImage: `url(/static/images/candidate/${this.province}/${this.municipality}/${item.no}.jpg)`}}>
                        </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }

    render() {

        let xncButton;
        if (!this.hub.xncTransparent) {
            xncButton = (
                <button type="button" className={`btn btn-nc btn-xnc ${this.state.filter[2] ? 'active' : ''}`} onClick={() => { this.toggleFilter(2)}}>
                    <div className="checkbox-dummy">
                        {this.state.filter[2] ? <i className="fa fa-check-circle-o fa-lg"></i> : <i className="fa fa-circle-o fa-lg"></i>}
                    </div>
                    <div className="checkbox-label">จุดยืนไม่ชัด</div>
                </button>
            )
        }

        const votePvt = this.hub.votePvt[this.province]


        return (
            <div className="container">
                <Link to="/candidates" className="btn btn-back btn-light lh0 d-print-none"><i className="fa fa-chevron-left fa-2"/></Link>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-8 mb-4">
                        <div className="location-top text-center">
                            <h3>{this.province}</h3>
                            <h2>{this.municipality}</h2>
                        </div>
                    </div>
                </div>

                <div className="location-middle">
                    <div className="row">
                        {this.candidates.map(item => this.renderCandidateItem(item))}
                    </div>
                </div>

                {false && votePvt?
                <div className={`d-flex justify-content-between`}>
                    <div className={`text-left`}>
                        <h5 className={`mb-0`}><small>จำนวนผู้มีสิทธิ์์เลือกตั้ง</small></h5>
                        <h3>{numberWithCommas(votePvt['total voter'])}</h3>
                    </div>
                    <div className={`text-right`}>
                        <h5 className={`mb-0`}><small>จำนวนหน่วยเลือกตั้งในจังหวัด</small></h5>
                        <h3>{numberWithCommas(votePvt['total station'])}</h3>
                    </div>
                </div>
                : ``}

            </div>
        )
    }
}
