import React, {Component} from "react";

export default class HomeFooter extends Component {

  render = () => {

    const startInputDateTime = this.props.hub.setting.startInputDateTime
    
    return (
      <>
        <div className="pt-4 bg-dark-grey justify-content-end" style={{width: '100%'}}>
          <div className="container" style={{maxWidth: 850}}>
              <div className="row">

                  <div className="col-12 order-1 order-lg-2 text-white">
                      <div className={`text-center`}>
                          <h2>วันที่ {startInputDateTime.toLocaleDateString('th', {day: 'numeric', month: 'long', year: 'numeric'})}</h2>
                          <h5>
                              หลังปิดคูหา เวลาตั้งแต่ {startInputDateTime.toLocaleTimeString('th', {hour: 'numeric', minute: 'numeric'})} น. เป็นต้นไป<br />
                              สามารถมีส่วนร่วมผ่าน Vote62
                          </h5>
                      </div>
                      <div className="row mt-5">
                          <div className="col-12 col-sm-6 mb-0 mb-sm-3 d-flex align-items-start flex-row">
                              <img src="/static/images/icon-camera-white.png" srcSet="/static/images/icon-camera-white.png 1x, /static/images/icon-camera-white@2x.png 2x"  style={{ flex: '0 0 36px', width: '36px', height: '28px' }}/>
                              <div className="bg-orange text-center text-white rounded-circle mx-2 _fs-24 _fw-bd" style={{ flex: '0 0 28px', width: '28px', height: '28px', lineHeight: '28px' }}>1</div>
                              <p className="_fw-reg _fs-20 text-white">ไปดูการนับคะแนนที่หน่วยเลือกตั้งเพื่อถ่ายรูปกระดานที่นับคะแนนเสร็จแล้วอัปโหลดรูปภาพเก็บไว้ในระบบ</p>
                          </div>
                          <div className="col-12 col-sm-6 mb-0 mb-sm-3 d-flex align-items-start flex-row">
                              <img src="/static/images/icon-report-white.png" srcSet="/static/images/icon-report-white.png 1x, /static/images/icon-report-white@2x.png 2x" style={{ flex: '0 0 33px', width: '33px', height: '33px' }}/>
                              <div className="bg-orange text-center text-white rounded-circle mx-2 _fs-24 _fw-bd" style={{ flex: '0 0 28px', width: '28px', height: '28px', lineHeight: '28px' }}>2</div>
                              <p className="_fw-reg _fs-20 text-white">ช่วยกัน Digitize คะแนน หรือดูภาพแล้วกรอกตัวเลขคะแนน ยิ่งกรอกซ้ำ กรอกมาก ยิ่งมั่นใจในความแม่นยำ</p>
                          </div>
                          <div className="col-12 mt-2 text-center">
                              {/* <div className={`mt-4 m-2 d-inline-block`}>
                                  <a className={`btn btn-dark btn-lg`} target={`_blank`} href={`/static/vote62-volunteer-manual.pdf`}>คู่มือรายงานผล นายก อบจ.</a>
                              </div> */}
                              <div className={`mt-2 m-2 d-inline-block`}>
                                  <a className={`btn bg-orange text-white btn-lg`} target={`_blank`} href={`http://electionwatchth.org`}>รายงานสถานการณ์เลือกตั้ง</a>
                              </div>
                          </div>

                          <div className="col-12 d-flex flex-column align-items-center justify-content-end">
                              <img src="/static/images/volunteer.png" srcSet="/static/images/volunteer.png 1x, /static/images/volunteer@2x.png 2x" />
                          </div>
                  
                      </div>
                  </div>
              </div>

            </div>
        </div>

        <div className="container-fluid bg-white">
            <div className="text-center py-3">
                <div className={`p-4 d-inline-block`}>
                    <img src="/static/images/logo-partner/swiss-embassy-logo-color.png" srcSet="/static/images/logo-partner/swiss-embassy-logo-color.png 1x, /static/images/logo-partner/swiss-embassy-logo-color@2x.png 2x" style={{maxWidth: `100%`, height: `auto`}} />
                </div>
                <div className={`p-4 d-inline-block`}>
                    <img src="/static/images/logo-partner/king-prapok-logo-color.png" srcSet="/static/images/logo-partner/king-prapok-logo-color.png 1x, /static/images/logo-partner/king-prapok-logo-color@2x.png 2x" />
                </div>
                <div className={`p-4 d-inline-block`}>
                    <img src="/static/images/logo-partner/wewatch-logo-color.jpg" srcSet="/static/images/logo-partner/wewatch-logo-color.jpg 1x, /static/images/logo-partner/wewatch-logo-color@2x.jpg 2x" />
                </div>
                <div className={`p-4 d-inline-block`}>
                    <img src="/static/images/logo-partner/ilaw-logo-color.png" srcSet="/static/images/logo-partner/ilaw-logo-color.png 1x, /static/images/logo-partner/ilaw-logo-color@2x.png 2x" />
                </div>
                <div className={`p-4 d-inline-block`}>
                    <img src="/static/images/logo-partner/opendream-logo-color.svg" height="40" />
                </div>
            </div>
        </div>

        <div className="bg-dark text-white px-3 py-1 text-center" style={{width: '100%'}}>
            <h6 className={`d-inline-block mb-0`}>การเลือกตั้งที่ผ่านมา : &nbsp;</h6>
            <a href="https://pao.vote62.com/" className={`d-inline-block text-white`}>
                • เลือกตั้งนายก อบจ.
            </a>
            <a href="https://s5.vote62.com/" className={`d-inline-block text-white`}>
                • เลือกตั้งซ่อมสมุทรปราการเขต 5
            </a>
            &nbsp;
            <a href="https://l4.vote62.com/" className={`d-inline-block text-white`}>
                • เลือกตั้งซ่อมลำปางเขต 4
            </a>
            &nbsp;
            <a href="https://k4.vote62.com/" className={`d-inline-block text-white`}>
                • เลือกตั้งซ่อมกำแพงเพชรเขต 4
            </a>
            &nbsp;
            <a href="https://k7.vote62.com/" className={`d-inline-block text-white`}>
                • เลือกตั้งซ่อมขอนแก่นเขต 7
            </a>
            &nbsp;
            <a href="https://election.vote62.com/" className={`d-inline-block text-white`}>
                • เลือกตั้งทั่วไป 24 มีนาคม
            </a>
        </div>

      </>
    )
  }
}